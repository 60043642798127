import { GroovyCard } from "../components/GroovyCard";
import { Button, Grid } from "@applyboard/ui-components";
import { GroovyUploader } from "../components/GroovyUploader";
import { useEffect } from "react";
import { useStepperContext } from "../utils/context";

// const useStyles = makeStyles({
//   button: {
//     "& .MuiButton-root": {
//       flex: "1 1",
//       padding: "12px 16px",
//       height: "48px",
//     },
//     "& .MuiTypography-root": {
//       fontFamily: "Montserrat",
//     },
//   },
// });
const StartStep = () => {
  // const classes = useStyles();
  const { setEmail, setDomain, setIsAbDomain } = useStepperContext();

  useEffect(() => {
    setEmail("");
    setDomain("");
    setIsAbDomain(false);
  });

  return (
    <>
      <GroovyCard id="start-step" title="Drop Student File to Begin">
        <GroovyUploader />
      </GroovyCard>
      <br />
      <br />
      <br />
      <Grid container justifyContent="center">
        <Button
          sx={{ fontFamily: "Montserrat" }}
          href="files/Student File Template -- AB Student Checker.csv"
          variant="contained"
          color="primary"
        >
          Download Student File Template CSV
        </Button>
      </Grid>
    </>
  );
};

export default StartStep;
