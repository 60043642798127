import { makeStyles, WHITE, withStyles } from "@applyboard/ui-components";

import { Step, Stepper, StepConnector, StepLabel } from "@mui/material";
import { CheckCircleFillIcon, CircleFillIcon, CircleOutlineIcon, ErrorFillIcon } from "@applyboard/ui-icons";

import { useStepperContext } from "../utils/context";
import { ApplicationStep } from "../utils/types";

const useStyles = makeStyles({
  stepper: {
    backgroundColor: "#f6f8fe",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  stepLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontFamily: "'Open Sans'",
      fontSize: "16px",
      height: "56px",
      background: `${WHITE}`,
      border: "1px solid #D7DAE8",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
  },
  stepTopImage: {
    position: "absolute",
    top: "-65px",
    left: "93px",
  },
});

export interface GroovyStep {
  displayName: string;
  topImage: string;
}

const steps: GroovyStep[] = [
  { topImage: "images/laptop.svg", displayName: "Drop your file to begin" },
  { topImage: "images/connections.svg", displayName: "Enter email details" },
  { topImage: "images/search.svg", displayName: "Searching" },
  { topImage: "images/group.svg", displayName: "Complete" },
];

export default function GroovyStepper() {
  const classes = useStyles();
  const { activeStep } = useStepperContext();

  const isStepFailed = (index: number) => index === ApplicationStep.Results && activeStep === ApplicationStep.Error;

  const renderStepIcon = (index: number) => {
    if (isStepFailed(index)) {
      return <ErrorFillIcon variant="" color="red" />;
    }

    return activeStep > index || activeStep === ApplicationStep.Results ? (
      <CheckCircleFillIcon color="positive" />
    ) : activeStep === index ? (
      <CircleFillIcon color="primary" />
    ) : (
      <CircleOutlineIcon color="#A9B1CC" />
    );
  };

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={true}
      className={classes.stepper}
      connector={<GroovyConnector />}
    >
      {steps.map((groovyStep, index) => {
        return (
          <Step key={groovyStep.displayName} completed={index < activeStep}>
            <img src={groovyStep.topImage} alt="" className={classes.stepTopImage} />
            <StepLabel icon={renderStepIcon(index)} className={classes.stepLabel}>
              {groovyStep.displayName}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const GroovyConnector = withStyles({
  root: {
    flex: "1 1 auto",
  },
  alternativeLabel: {
    top: "12px",
    left: "calc(-50% + 11px)",
    right: "calc(50% + 11px)",
    position: "absolute",
  },
  line: {
    display: "block",
    borderColor: "#D7DAE8",
  },
  lineHorizontal: {
    boderTopStyle: "solid",
    borderTopWidth: "2px",
  },
  active: {
    "& $line": {
      borderColor: "#087C69",
      // borderColor: "#1E6DEB",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#087C69",
    },
  },
  error: {
    "& $line": {
      borderColor: "red",
    },
  },
})(StepConnector);
