import { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@applyboard/ui-components";

import { GroovyCard } from "../components/GroovyCard";

import { useStepperContext } from "../utils/context";
import { ApplicationStep } from "../utils/types";
import { validateEmailAddress } from "../utils/validations";

const useStyles = makeStyles({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    marginLeft: "5px !important",
    marginRight: "10px !important",
  },
  disclaimer: {
    fontSize: "11px !important",
    paddingTop: "5px",
    lineHeight: "15px !important",
  },
  notes: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const EnterEmailStep = () => {
  const classes = useStyles();
  const [schoolConsent, setSchoolConsent] = useState<boolean>(false);

  const { file, setActiveStep, email, setEmail, domain, setDomain, isAbDomain, setIsAbDomain } =
    useStepperContext();

  const validateForm = () => {
    return isAbDomain
      ? validateEmailAddress(email) && domain?.length > 0
      : validateEmailAddress(email) && schoolConsent;
  };

  const ApplyboardAdditionalFields = () => (
    <Box>
      <Typography variant="caption" align="left" className={classes.disclaimer}>
        Please ensure that the School Domain is valid and approved. Once the Student Searcher is
        complete, an email, containing a CSV file with the results of this search, will be sent to
        your email addresse listed, here.
      </Typography>
    </Box>
  );

  const SchoolAdditionalFields = () => (
    <Box>
      <Typography variant="caption" align="left" className={classes.notes}>
        Once your file is scanned, you will receive an email with the results. Only approved emails
        will receive an email with results.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={schoolConsent}
            onChange={(e) => setSchoolConsent(e.target.checked)}
            className={classes.checkbox}
            data-testid="schoolConsentCheckbox"
          />
        }
        label={
          <Typography className={classes.disclaimer}>
            ApplyBoard is committed to protecting and respecting your privacy, and by submitting
            your information you understand and agree that we will use your personal information to
            assist in providing you with (insert description). For further information, please see
            our privacy policy provided at{" "}
            <a href="https://www.applyboard.com/privacy-policy" target="_blank" rel="noreferrer">
              https://www.applyboard.com/privacy-policy
            </a>
            .
          </Typography>
        }
      />
    </Box>
  );

  const RenderAdditionalFields = (props) => {
    return props.isAbDomain ? <ApplyboardAdditionalFields /> : <SchoolAdditionalFields />;
  };

  return (
    <GroovyCard
      title="Enter Email and Begin Student Search"
      footerButtons={() => (
        <>
          <Button
            onClick={() => setActiveStep(ApplicationStep.Initial)}
            variant="text"
            color="secondary"
          >
            {" "}
            Cancel
          </Button>
          <Button
            onClick={() => setActiveStep(ApplicationStep.Process)}
            disabled={!validateForm()}
            variant="contained"
            color="primary"
            data-testid="beginSearchButton"
          >
            Begin Search
          </Button>
        </>
      )}
    >
      <Box className={classes.box}>
        <img src="images/csv.svg" alt="" />
        <Typography variant="caption" data-testid="filenameLabel">
          {file.current.path}
        </Typography>
      </Box>
      <Box>
        <TextField
          id="email"
          variant="outlined"
          label={isAbDomain ? "ApplyBoard Email" : "Email"}
          required
          fullWidth
          data-testid="emailInput"
          autoFocus={true}
          value={email}
          onChange={(event) => {
            const newValue: string = event.target.value;
            setEmail(newValue);
            //eslint-disable-next-line
            setIsAbDomain(/^\w+([\.-]?\w+)*@applyboard.com$/.test(newValue));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && validateForm()) setActiveStep(ApplicationStep.Process);
          }}
        />
        {isAbDomain && (
          <>
            <br />
            <br />
            <TextField
              id="domain"
              variant="outlined"
              label="School's Domain"
              className={classes.textField}
              data-testid="domainInput"
              required
              fullWidth
              value={domain}
              onChange={(event) => setDomain(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter" && validateForm()) setActiveStep(ApplicationStep.Process);
              }}
            />
          </>
        )}
        <RenderAdditionalFields isAbDomain={isAbDomain} />
      </Box>
    </GroovyCard>
  );
};

export default EnterEmailStep;
