import { useContext, createContext } from "react";

export type ContextProps = {
  activeStep: any;
  setActiveStep: any;
  file: any;
  results: any;
  setResults: any;
  email: any;
  setEmail: any;
  domain: any;
  setDomain: any;
  isAbDomain: any;
  setIsAbDomain: any;
  errorMessage: any;
  setErrorMessage: any;
};

export const StepperContext = createContext<Partial<ContextProps>>({});

export function useStepperContext() {
  return useContext(StepperContext);
}
