import styled from "styled-components";

import { CardLegacy, GRAY } from "@applyboard/ui-components";

export const CustomCardLegacy = styled(CardLegacy)`
  width: 500px;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(59, 65, 83, 0.14),
    0px 4px 16px rgba(59, 65, 83, 0.12), 0px 6px 18px rgba(59, 65, 83, 0.18) !important;
  border-radius: 8px;
  color: #363b51;
  font-family: "'Open Sans'";
`;

export const GroovyCardBody = styled.div`
  && {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GroovyCardFooter = styled.div`
  border-top: solid 1px ${GRAY[18]};
  box-sizing: border-box;
  display: flex;
  padding: 8px;

  .MuiButton-root {
    flex: 1 1;
    padding: 12px 16px;
    height: 48px;
  }

  .MuiTypography-root {
    font-family: "Montserrat";
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 48px;
  min-height: 48px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: solid 1px ${GRAY[18]};
`;

export const Title = styled.p`
  margin: 0px;
  padding: 16px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;
