import { Button, Grid, makeStyles, Typography } from "@applyboard/ui-components";

import { GroovyTile } from "../components/GroovyTile";

import { useStepperContext } from "../utils/context";
import { ApplicationStep } from "../utils/types";

const useStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontSize: "28px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#242938",
    marginTop: "-10px",
    marginBottom: "10px",
  },
  matchesBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: "26px",
    marginBottom: "36px",
    flexGrow: 1,
    width: "100%",
    gap: "33px",
  },
  img: {
    marginTop: "-20px",
  },
});

const FinishStep = () => {
  const classes = useStyles();
  const { setActiveStep, results } = useStepperContext();

  return (
    <Grid container direction="column" alignItems="center">
      <img
        src="images/search_complete.svg"
        width={200}
        height={200}
        className={classes.img}
        alt=""
      />
      <Typography variant="h3" className={classes.title}>
        Search Complete
      </Typography>
      <Typography variant="caption">
        If you entered an approved email address, you will receive an email with those results
        within 15 minutes.
      </Typography>
      <div className={classes.matchesBox}>
        <GroovyTile title="STUDENTS CHECKED" score={results.studentsChecked ?? 0} color="black" />
        <GroovyTile title="PERFECT MATCHES" score={results.perfectMatches ?? 0} color="#098972" />
        <GroovyTile title="FUZZY MATCHES" score={results.fuzzyMatches ?? 0} color="#D96432" />
        <GroovyTile
          title="ERRORS<br />(EX: INVALID STUDENT #)"
          score={results.errors ?? 0}
          color="#76132D"
        />
      </div>
      <Button
        className="downloadTemplateButton"
        onClick={() => setActiveStep(ApplicationStep.Initial)}
      >
        Start a New Search
      </Button>
    </Grid>
  );
};

export default FinishStep;
