import { useEffect } from "react";
import { Button, Grid, Typography } from "@applyboard/ui-components";
import { CircularProgress } from "@material-ui/core";
import Papa from "papaparse";

import { GroovyCard } from "../components/GroovyCard";

import API from "../utils/api";
import { useStepperContext } from "../utils/context";
import { ApplicationStep, Student } from "../utils/types";

const parseCsvToJson = (file: any) => {
  return new Promise<Array<Student>>((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete: (results: any) => {
        const studentsFromCsv: Student[] = results.data as Student[];
        resolve(studentsFromCsv);
      },
    });
  });
};

const ProcessStep = () => {
  const { setActiveStep, file, setResults, email, domain, isAbDomain, setErrorMessage } =
    useStepperContext();

  useEffect(() => {
    async function processCsvFile() {
      const students: Student[] = await parseCsvToJson(file.current);
      await API.post("/api/v1/searchStudents", {
        filename: file.current.path,
        email,
        domain: isAbDomain ? domain : null,
        students,
      })
        .then((res) => {
          if (res.data.success) {
            console.log("Data processed successfully");
            setResults(res.data.result);
          } else {
            console.log(`Error retrieving results from the API: ${res.data.error}`);
          }

          setActiveStep(ApplicationStep.Results);
        })
        .catch((error) => {
          console.log(`Error response: ${JSON.stringify(error.response.data)}`);

          if (
            ["INVALID_EMAIL_ADDRESS", "INVALID_SCHOOL_DOMAIN"].includes(error.response.data.type)
          ) {
            setErrorMessage(error.response.data.message);
          }

          setActiveStep(ApplicationStep.Error);
        });
    }

    processCsvFile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GroovyCard
      title="Search in Progress"
      footerButtons={() => (
        <Button
          onClick={() => setActiveStep(ApplicationStep.Initial)}
          variant="contained"
          color="primary"
        >
          Cancel and Start Over
        </Button>
      )}
    >
      <Grid container justifyContent="center">
        <CircularProgress size={135} variant="indeterminate" />
      </Grid>
      <Typography variant="caption">
        Upon completion of the comparison between your file, and ApplyBoard's records, you will
        receive an email with the results. Only approved email addresses will receive an email with
        search results
      </Typography>
    </GroovyCard>
  );
};

export default ProcessStep;
