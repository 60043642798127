import { makeStyles, WHITE } from "@applyboard/ui-components";

const useStyles = makeStyles({
  navbar: {
    height: "78px",
    backgroundColor: `${WHITE}`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navbarLogo: {
    marginLeft: "162px",
  },
});

const NavBar = () => {
  const classes = useStyles();
  return (
    <nav className={classes.navbar}>
      <img
        alt="Applyboard Logo"
        src="images/abLogo.svg"
        className={classes.navbarLogo}
      />
    </nav>
  );
};

export default NavBar;
