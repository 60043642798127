import React from "react";

import {
  TitleContainer,
  Title,
  CustomCardLegacy,
  GroovyCardBody,
  GroovyCardFooter,
} from "./GroovyCardStyles";
import { useId } from "@applyboard/ui-components";

type GroovyCardTitleProps = {
  id?: string;
  title: React.ReactNode;
};
const GroovyCardTitle = ({ id, title }: GroovyCardTitleProps) => (
  <TitleContainer>
    <Title variant="h6" id={id}>
      {title}
    </Title>
  </TitleContainer>
);

const GroovyCardContent = ({ children }) => (
  <GroovyCardBody>{children}</GroovyCardBody>
);

export interface GroovyCardProps {
  id?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  footerButtons?: React.ComponentType;
}

export function GroovyCard({
  id,
  title,
  children,
  footerButtons: ButtonsComponent,
  ...props
}: GroovyCardProps) {
  const genId = useId();
  const modalId = id || genId;

  return (
    <CustomCardLegacy variant="elevated" {...props} id={id}>
      <GroovyCardTitle id={`${modalId}-title`} title={title} />
      <GroovyCardContent>{children}</GroovyCardContent>
      {ButtonsComponent && (
        <GroovyCardFooter>
          <ButtonsComponent />
        </GroovyCardFooter>
      )}
    </CustomCardLegacy>
  );
}
