import Dropzone from "react-dropzone";
import { Button, makeStyles, Typography } from "@applyboard/ui-components";

import { useStepperContext } from "../utils/context";
import { ApplicationStep } from "../utils/types";

const useStyles = makeStyles({
  container: {
    "& div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  img: {
    marginTop: "-10px",
    marginLeft: "-15px",
  },
  notes: {
    color: "gray",
    paddingTop: "15px !important",
    marginBottom: "-15px",
  },
});

export function GroovyUploader() {
  const classes = useStyles();
  const { file, setActiveStep } = useStepperContext();

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0 && file) {
      file.current = acceptedFiles[0];
      setActiveStep(ApplicationStep.EnterEmail);
    } else {
    }
  };

  return (
    <Dropzone onDrop={onDrop} maxFiles={1} accept={{ "text/csv": [".csv"] }} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <section className={classes.container}>
          <div {...getRootProps({ className: "dropzone" })}>
            <img src="images/upload.svg" width={184} height={184} className={classes.img} alt="" />
            <Button {...getInputProps} className="searchMyFilesButton" variant="contained" color="primary">
              Search My Files
            </Button>
            <Typography variant="caption" className={classes.notes}>
              (Only *.csv files will be accepted)
            </Typography>
            <br />
          </div>
        </section>
      )}
    </Dropzone>
  );
}
