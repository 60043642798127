import { useRef, useState } from "react";
import { ABThemeProvider, Container, makeStyles, Typography } from "@applyboard/ui-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import "./FadeTransition";

import NavBar from "./components/NavBar";
import GroovyStepper from "./components/GroovyStepper";
import StartStep from "./pages/StartStep";
import EnterEmailStep from "./pages/EnterEmailStep";
import ProcessStep from "./pages/ProcessStep";
import FinishStep from "./pages/FinishStep";
import ErrorStep from "./pages/ErrorStep";
import Footer from "./components/Footer";
import { ApplicationStep } from "./utils/types";
import { StepperContext } from "./utils/context";

const useStyles = makeStyles({
  container: {
    maxWidth: "975px",
    padding: "0px",
    backgroundColor: "#f6f8fe",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "80px",
  },
  title: {
    fontStyle: "normal",
    fontSize: "28px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#242938",
    marginTop: "10px",
    marginBottom: "10px",
  },
});

function App() {
  const classes = useStyles();
  const file = useRef(null);
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [isAbDomain, setIsAbDomain] = useState<boolean>(false);
  const [results, setResults] = useState({});
  const [activeStep, setActiveStep] = useState(ApplicationStep.Initial);
  const [errorMessage, setErrorMessage] = useState("");

  const renderStepContent = (step: any) => {
    switch (step) {
      case 0:
        return <StartStep />;
      case 1:
        return <EnterEmailStep />;
      case 2:
        return <ProcessStep />;
      case 3:
        return <FinishStep />;
      case 4:
        return <ErrorStep />;
      default:
        return <div>Not Found</div>;
    }
  };

  const isSearchComplete = [ApplicationStep.Results, ApplicationStep.Error].includes(activeStep);

  return (
    <ABThemeProvider>
      <NavBar />
      <StepperContext.Provider
        value={{
          activeStep,
          setActiveStep,
          file,
          results,
          setResults,
          email,
          setEmail,
          domain,
          setDomain,
          isAbDomain,
          setIsAbDomain,
          errorMessage,
          setErrorMessage,
        }}
      >
        <Container className={classes.container}>
          <GroovyStepper />
          {!isSearchComplete && (
            <Typography variant="h3" className={classes.title}>
              Student Checker
            </Typography>
          )}
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeStep}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="fade-container">
                <div className="animate">{renderStepContent(activeStep)}</div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Container>
      </StepperContext.Provider>
      <Footer />
    </ABThemeProvider>
  );
}

export default App;
