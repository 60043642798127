import { makeStyles, Typography, WHITE } from "@applyboard/ui-components";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "#004DC0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "74px",
    width: "100%",
  },
  footerText: {
    color: `${WHITE}`,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "16px",
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography className={classes.footerText}>
        GDPR Compliant. No data is saved after matching process is completed.
      </Typography>
    </footer>
  );
};

export default Footer;
