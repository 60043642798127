export interface Student {
  student_number: string;
  term: string;
  reg_status: string;
  credit: string;
  cum_credits: string;
  program_code: string;
  program_name: string;
}

export enum ApplicationStep {
  Initial = 0,
  EnterEmail = 1,
  Process = 2,
  Results = 3,
  Error = 4,
}
