import { Button, Grid, makeStyles, Typography } from "@applyboard/ui-components";

import { useStepperContext } from "../utils/context";
import { ApplicationStep } from "../utils/types";

const useStyles = makeStyles({
  oops: {
    fontSize: "96px !important",
    lineHeight: "117.02px !important",
    textAlign: "center",
    color: "#242938",
    paddingTop: "50px",
    paddingBottom: "40px",
  },
  errorMessage: {
    fontSize: "28px",
    color: "#242938",
    lineHeight: "40px !important",
    fontStyle: "normal",
    fontWeight: "600",
    paddingBottom: "45px",
    textAlign: "center",
  },
  button: {
    padding: "12px 24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "0px",
    border: "0px",
    "& .MuiTypography-body1": {
      fontFamily: "Montserrat !important",
      fontStyle: "normal",
      fontWeight: "600",
    },
  },
});

const FinishStep = () => {
  const classes = useStyles();
  const { setActiveStep, errorMessage } = useStepperContext();

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h3" className={classes.oops}>
        Oops, Something Went Wrong!
      </Typography>
      <Typography display="block" variant="h3" component="pre" className={classes.errorMessage}>
        {errorMessage.length > 0 ? errorMessage : `There was a problem with your search.\nPlease try searching, again!`}
      </Typography>
      <Button onClick={() => setActiveStep(ApplicationStep.Initial)} className={classes.button}>
        Start a New Search
      </Button>
    </Grid>
  );
};

export default FinishStep;
