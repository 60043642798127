import { makeStyles, Typography, WHITE } from "@applyboard/ui-components";

const useStyles = makeStyles({
  tile: {
    width: "225px",
    height: "150px",
    background: `${WHITE}`,
    boxShadow:
      "0px 0.908633px 4.54316px rgba(59, 65, 83, 0.14), 0px 1.81727px 7.26906px rgba(59, 65, 83, 0.12), 0px 2.7259px 8.1777px rgba(59, 65, 83, 0.18)",
    borderRadius: "3.63453px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#44566C",
    marginBottom: "15px",
  },
  score: {
    fontFamily: "Montserrat !important",
    fontSize: "40px !important",
    marginBottom: "45px !important",
  },
});

export type GroovyTileProps = {
  title: string;
  score: number;
  color: string;
};

export function GroovyTile({ title, score, color }: GroovyTileProps) {
  const classes = useStyles();
  return (
    <div className={classes.tile}>
      <div
        dangerouslySetInnerHTML={{ __html: title }}
        className={classes.title}
      />
      <Typography className={classes.score} color={color}>
        {score}
      </Typography>
    </div>
  );
}
